import {
  ListingPageTab,
  ViewKey,
  TaskCategory,
  UnitType,
  UpdatePropertyBodyType,
  KeyPlaceProfile,
  KeyPlaceType,
  Building,
  AddRoomBody,
  CheckinInstructionType,
  PropertyBatchUpdateResponseType,
  UnittypeBatchUpdateResponseType,
  ChannelManager,
} from 'src/@types/listings';
import axios from '../utils/axios';
import { Meta, Booking, Image as ImageType } from 'src/@types/common';
import { ArchiveListingsParams, ListingDefaultSettingsFormikValues, SwitchChannelManagerResponseType } from 'src/@types/listing';

function listingsAPI() {
  return {
    fetchActions: (property?: number | null) => {
      const url = `/apis/listing/actions`;
      return axios.get(url, {
        params: {
          property,
        },
      });
    },
    fetchOverview: () => {
      const url = `/apis/listing/overview`;
      return axios.get(url);
    },
    switchListingChannelManager: (id: number, channel_manager: Omit<ChannelManager, 'none'>) => {
      const url = `/apis/listing/cm/switch/${id}`;
      return axios.post<SwitchChannelManagerResponseType>(url, { channel_manager });
    },
    fetchUnitsChart: () => {
      const url = `/apis/listing/units_chart`;
      return axios.get(url);
    },
    fetchLicensesChart: (id?: number | null) => {
      const url = `/apis/listing/licenses_chart`;
      return axios.get(url, {
        params: {
          property_id: id,
        },
      });
    },
    fetchTotalSale: (
      from: string,
      to: string,
      order_by: string = 'total_sale',
      dir: 'asc' | 'desc',
      limit: number = 10,
      offset: number = 0
    ) => {
      const url = `/apis/listing/total_sales`;
      return axios.get(url, {
        params: {
          from,
          to,
          order_by,
          dir,
          limit,
          offset,
        },
      });
    },
    fetchProperties: (
      show_inactive: boolean | number = 0,
      limit: number = 10,
      offset: number = 0,
      query: string,
      signal: any
    ) => {
      const url = `apis/listing/properties`;
      return axios.get(url, {
        signal: signal,
        params: {
          show_inactive,
          limit,
          offset,
          shortName: query,
          name: query,
        },
      });
    },
    fetchListingWizards: (organization_id: number = 0, page: number) => {
      const url = `apis/wizards?organization=${organization_id}&status[]=init&status[]=in_progress&page=${page}`;
      return axios.get(url);
    },
    fetchPropertyBookings: (params: {
      pageNo: number;
      limit: number;
      propertyId: number;
      searchTerm?: string;
    }) => {
      const { pageNo, limit, propertyId, searchTerm = '' } = params;
      return axios.get<{ data: Booking[]; meta: Meta }>('apis/property/list/bookings', {
        params: {
          page: pageNo,
          limit,
          property: propertyId,
          search: searchTerm,
        },
      });
    },
    removeWizard: (id) => {
      const url = `apis/wizards/${id}`;
      return axios.delete(url);
    },
    fetchConfig: (key: ViewKey, id: number) => {
      const url = '/apis/listing/config';
      return axios.get(url, { params: { [key]: id } });
    },
    fetchProperty: (tab: ListingPageTab, id: number) => {
      const url = `/apis/listing/property/${id}?video=1`;
      return axios.get(url, { params: { tab } });
    },
    fetchUnitType: (tab: ListingPageTab, id: number) => {
      const url = `/apis/listing/unit_type/${id}`;
      return axios.get(url, { params: { tab } });
    },
    fetchUnit: (tab: ListingPageTab, id: number) => {
      const url = `apis/listing/unit/${id}?video=1`;
      return axios.get(url, { params: { tab } });
    },
    updateProperty: (id: number, body: Partial<UpdatePropertyBodyType>) => {
      const bodyAsArray = Object.entries(body);
      let params = 'video=1';
      const maps = {
        property_images: 'images',
        custom_css: 'property_page_css',
        earliest_free_checkin_time: 'standard_check_in_time',
        earliest_free_checkout_time: 'earliest_free_check_out_time',
        earliest_possible_checkin_time: 'earliest_possible_check_in_time',
        earliest_possible_checkout_time: 'earliest_possible_check_out_time',
        latest_free_checkin_time: 'latest_free_check_in_time',
        latest_free_checkout_time: 'standard_check_out_time',
        latest_possible_checkin_time: 'latest_possible_check_in_time',
        latest_possible_checkout_time: 'latest_possible_check_out_time',
        midstay_housekeeping_frequency: 'mid_stay_housekeeping_period',
        midstay_housekeeping_frequency_overridden: 'mid_stay_housekeeping_frequency_overridden',
        early_checkin_addon: 'early_check_in_addon',
        early_checkin_addon_price: 'early_check_in_addon_price',
        early_checkin_addon_type: 'early_check_in_addon_type',
        early_checkout_addon: 'early_check_out_addon',
        early_checkout_addon_price: 'early_check_out_addon_price',
        early_checkout_addon_type: 'early_check_out_addon_type',
        late_checkout_addon: 'late_check_out_addon',
        late_checkout_addon_price: 'late_check_out_addon_price',
        late_checkout_addon_type: 'late_check_out_addon_type',
        late_checkin_addon: 'late_check_in_addon',
        late_checkin_addon_price: 'late_check_in_addon_price',
        late_checkin_addon_type: 'late_check_in_addon_type',
        default_currency: 'default_currency',
        allow_show_in_search_engine: 'search_engine_allow_show',
        follow_link_in_the_page: 'search_engine_follow_link',
      };
      bodyAsArray.forEach((item, i) => {
        params = `${params}${i > 0 ? '&' : '?'}properties[]=${maps[item[0]] || item[0]}`;
      });
      const url = `/apis/properties/${id}?${params}`;
      return axios.put(url, body);
    },
    updateUnitType: (id: number, body) => {
      const url = `/apis/unit_types/${id}`;
      return axios.put(url, body);
    },
    updateUnit: (id: number, body, tab?: ListingPageTab) => {
      const url = `/apis/units/${id}?video=1${tab === 'instructions' ? '&groups[]=unit:instruction:read' : ''
        }`;
      return axios.put(url, body);
    },
    updatePropertyUnits: (propertyId: number, body) =>
      axios.put<PropertyBatchUpdateResponseType>(`/apis/properties/${propertyId}/units/batch_update`, body),
    updateUnitTypeUnits: (unitTypeId: number, body) =>
      axios.put<UnittypeBatchUpdateResponseType>(`/apis/unit_types/${unitTypeId}/units/batch_update`, body),
    removeListingValidate: (id: number, type: ViewKey) =>
      axios.get(`/apis/listing/validate/${type}/removal/${id}`),
    removeProperty: (id: number) =>
      axios.post(`/apis/property/delete`, {
        propertyId: id,
      }),
    removeUnitType: (id: number) => axios.delete(`/apis/unit_types/${id}`),
    removeUnit: (id: number) => axios.delete(`/apis/units/${id}`),
    createUnit: (
      name: string,
      unit_type: number,
      check_in_backup_types?: {
        check_in_type: number | null;
        code: string | null;
        priority: number;
      }[],
      is_active: boolean = true
    ) => {
      const url = `/apis/units`;
      return axios.post(url, {
        name,
        unit_type,
        check_in_backup_types,
        is_active,
      });
    },
    createUnitType: (unitType: UnitType) => {
      const url = `/apis/unit_types`;
      return axios.post(url, {
        ...unitType,
      });
    },
    allocateUnits: (units: number[]) => axios.post('/apis/listing/activate', { units }),
    fetchAmenities: () => axios.get(`/apis/v2/amenities?pagination_enabled=0`),
    removePropertyAmenity: (propertyId: number, amenityId: number) =>
      axios.delete(`/apis/properties/${propertyId}/amenities/${amenityId}`),
    removeUnitTypeAmenity: (unitTypeId: number, amenityId: number) =>
      axios.delete(`/apis/unit_types/${unitTypeId}/amenities/${amenityId}`),
    /** Upload one image for property */
    uploadAPropertyImage: ({
      propertyId,
      priority,
      image_caption,
      fileId,
      thumbnailId,
    }: {
      propertyId: number;
      priority: number;
      image_caption: string;
      fileId: number;
      thumbnailId?: number;
    }) => {
      const url = `apis/property_images`;
      return axios.post<ImageType>(url, {
        property: propertyId,
        priority,
        image_caption,
        file: fileId,
        video_thumbnail: thumbnailId || null
      });
    },
    /** ------- */
    /** Upload one image for Unit */
    uploadAUnitImage: ({
      unitId,
      image_caption,
      fileId,
      priority,
      thumbnailId,
    }: {
      unitId: number;
      image_caption: string;
      fileId: number;
      priority: number;
      thumbnailId?: number;
    }) => {
      const url = `apis/unit_images`;
      return axios.post<ImageType>(url, {
        property_unit: unitId,
        image_caption,
        file: fileId,
        priority,
        video_thumbnail: thumbnailId || null
      });
    },
    /** ------- */
    fetchAbbImportWizard: (importURL: string) => {
      const url = `apis/listing/abb_import`;
      return axios.get(url, {
        params: {
          listing_url: importURL,
          import_type: 'import_as_new',
        },
      });
    },
    fetchAbbImportWizardNoAuth: (importURL: string) => {
      const url = `apis/listing/no_auth/abb_import`;
      return axios.get(url, {
        params: {
          listing_url: importURL,
          import_type: 'import_as_new',
        },
      });
    },
    fetchMarketingWebsiteFromBrand: () => {
      const url = `/apis/brands`;
      return axios.get(url);
    },
    removeTaskResponsibility: (id: number) => axios.delete(`/apis/task_responsibles/${id}`),
    addTaskResponsibility: (
      property: number,
      relatedId: {
        staff?: number;
        team?: number;
        company?: number;
      },
      task_category: TaskCategory
    ) => {
      const url = '/apis/task_responsibles';
      return axios.post(url, { property, ...relatedId, task_category });
    },
    fetchCheckinTypes: () => axios.get(`/apis/check_in_types`),
    fetchFaqCategories: () => axios.get('/apis/faq/get_category_types'),
    fetchParkingTypes: () => axios.get(`/apis/parking_types`),
    fetchKeyPlaceProfiles: () => axios.get<KeyPlaceProfile[]>('/apis/key_place_profiles'),
    addKeyPlaceProfile: (body: {
      address: {
        lat: string;
        lng: string;
        first_line: string;
        second_line: null;
        city: string;
        postal_code: string;
        region: string | null;
        country: string | null;
      };
      key_place_type: KeyPlaceType;
      name: string;
      opening_times: string | null;
    }) => axios.post<KeyPlaceProfile>('/apis/key_place_profiles', body),
    updateKeyPlaceProfile: (
      id: number,
      body: {
        address: {
          id: number;
          lat: string;
          lng: string;
          first_line: string;
          second_line: null;
          city: string;
          postal_code: string;
          region: string | null;
          country: string | null;
        };
        key_place_type?: KeyPlaceType;
        name?: string;
        opening_times?: string | null;
      }
    ) => axios.put<KeyPlaceProfile>(`/apis/key_place_profiles/${id}`, body),
    fetchKeySafeTypes: () => axios.get('/apis/key_safe_types'),
    importICal: (title: string, iCalLink: string, description: string, units: number[]) => {
      const url = '/apis/ical_imports';
      return axios.post(url, {
        title,
        i_cal_link: iCalLink,
        description,
        units,
      });
    },
    updateICal: (
      id: string | number,
      body: Partial<{
        title: string;
        i_cal_link: string;
        description: string;
        is_active: boolean;
        should_keep_blocked_dates_after_deactivation: boolean;
      }>
    ) => {
      const url = `/apis/ical_imports/${id}`;
      return axios.put(url, body);
    },
    updateBuilding: (id: number, body: Partial<Building>) => {
      const url = `/apis/buildings/${id}`;
      return axios.put(url, body);
    },
    fetchRoomTypes: (unitTypeId: string) =>
      axios.get(`/apis/room_types`, {
        params: { unit_type: unitTypeId },
      }),
    addRoom: (room: AddRoomBody) => axios.post('/apis/rooms', room),
    addRooms: (room: AddRoomBody) => axios.post('/apis/listing/rooms', room),
    removeRoom: (id: number) => axios.delete(`/apis/rooms/${id}`),
    updateRoom: (id: number, body: { label?: string }) => axios.put(`/apis/rooms/${id}`, body),
    fetchBedTypes: () => axios.get('apis/bed_types'),
    fetchBedSizes: () => axios.get('apis/bed_sizes'),
    updateRoomBedConfig: (roomBedConfig) => axios.post('apis/room_bed_configs', roomBedConfig),
    updateBed: (bedId, payload) => axios.put(`apis/room_bed_configs/${bedId}`, payload),
    removeBed: (bedId: number) => axios.delete(`apis/room_bed_configs/${bedId}`),
    fetchListingDefaultSettings: (id: number) => axios.get(`/apis/organization_defaults_configs`),
    fetchDefaultCheckInOutInstruction: (checkInTypeId: number) =>
      axios.get<CheckinInstructionType[]>('/apis/automated_message/v1/retrieve_settings', {
        params: { key: 'reset default', id: checkInTypeId },
      }),
    updateListingDefaultSettings: (
      body: ListingDefaultSettingsFormikValues,
      signal?: AbortSignal
    ) => {
      const { id, created_at, updated_at, ...other } = body;
      const url = `apis/organization_defaults_configs/${id}`;
      return axios.put(url, other, { signal });
    },
    getRecurrenceEndingMethod: () => axios.get(`/apis/expenses/get_recurrence_ending_method`),
    getRecurrenceRepeatPeriod: () => axios.get(`/apis/expenses/get_recurrence_repeat_period`),
    createRecurrenceRepeat: (body: {
      expense_id: number | string | null;
      repeat_value: number | null;
      repeat_period: string;
      ending_method: string;
      ends_on_date: Date | null;
      ends_after_occurrences: number | null;
      preview_schedule: boolean;
    }) => axios.post(`/apis/expenses/recurrence_expense`, body),
    createInternalNote: (propertyId: number, text: string) => {
      const url = '/apis/note';
      return axios.post(url, {
        entity: 'property',
        record_id: propertyId,
        text,
      });
    },
    updateInternalNote: (propertyId: number, noteId: number, text: string) => {
      const url = '/apis/note';
      return axios.put(url, {
        entity: 'property',
        record_id: propertyId,
        note_id: noteId,
        text: text,
      });
    },
    deleteInternalNote: (propertyId: number, noteId: number) => {
      const url = '/apis/note';
      return axios.delete(url, {
        data: {
          entity: 'property',
          record_id: propertyId,
          note_id: noteId,
        },
      });
    },
    fetchInactiveProperties: (
      params: { is_active: string } & ArchiveListingsParams,
      signal?: AbortSignal
    ) => {
      const url = `/apis/filter/properties`;
      return axios.get(url, {
        params,
        signal,
      });
    },
    deactivateUnitValidate: (unitId: number) =>
      axios.get(`/apis/listing/validate/unit/removal/${unitId}`),
    lastActiveUnitOfUnitTypeValidate: (unitTypeId: number) =>
      axios.get(`/apis/listing/validate/unit_type/removal/${unitTypeId}`),
    lastActiveUnitOfListingValidate: (propertyId: number) =>
      axios.get(`/apis/listing/validate/property/removal/${propertyId}`),
    deactivateListing: (units: number[]) => {
      const url = '/apis/listing/deactivate';
      return axios.post(url, { units });
    },
    activateListingValidate: (propertyId: number) =>
      axios.get(`apis/listing/activate_listing/${propertyId}`),
    activateListing: (propertyId: number) => {
      const url = `/apis/listing/activate_listing/${propertyId}`;
      return axios.post(url);
    },
    batchDeleteCheckinTypesOfProperty: (propertyId: number, checkinTypeId: number) => (
      axios.delete(`/apis/properties/${propertyId}/units/batch_check_in_type_delete/${checkinTypeId}`)
    ),
    batchDeleteCheckinTypesOfUnitType: (unitTypeId: number, checkinTypeId: number) => (
      axios.delete(`/apis/unit_types/${unitTypeId}/units/batch_check_in_type_delete/${checkinTypeId}`)
    ),
    moveUnitToUnitType: (unitId: number, unitTypeId: number, force?: boolean) => {
      const url = `/apis/listing/unit/move_to_unit_type`;
      return axios.post(url, {
        unit_id: unitId,
        unit_type_id: unitTypeId,
        force
      });
    },
  };
}

export default listingsAPI();
