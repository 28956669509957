import { File, Meta, Brand as BasicInfoBrand, ExportFileType, ApiFormat } from 'src/@types/common';
import { UpdateFaqs } from 'src/@types/listings';
import {
  Brand,
  BrandConfig,
  BrandFrontendStyle,
  ContactConfig,
  UpdateConfigBody,
  UpdateFrontendMenuBody,
  UpdateFrontendStyleBody,
  AllBrandsApiResType,
  CheckDnsResponse,
  ContentPage,
  UpdateContentPage,
  AllPages,
  DestinationConfig,
  UpdateDestinationConfig,
  UpdateContactConfig,
  UpdateTagAndCategory,
  TagAndCategory,
  City,
  Country,
  UpdateCity,
  UpdateCountry,
  NewsletterSubscriber,
  CreateNewsletterSubscriber,
  UpdateNewsletterSubscriber,
  NewsletterSubscribersInfo,
  UpdateRouteConfig,
  RouteConfig,
  CreateContentPage,
  CreateTagAndCategory,
  Property,
  UpdateProperty,
  CreateRedirectBodyType,
  Redirect,
} from 'src/@types/marketing';
import axios from 'src/utils/axios';

function MarketingAPI() {
  return {
    fetchBrand: (id: number) =>
      axios.get<Brand>(`/apis/brands/${id}`, {
        params: {
          properties: [
            'menus',
            'name',
            'id',
            'updated_at',
            'created_at',
            'faqs',
          ] as (keyof Brand)[],
          'properties[organization_config]': [
            'id',
            'whats_app_enabled',
            'sub_domain',
            'acceptable_custom_domain',
            'booking_engine_link',
            'domain_approved',
            'request_payment_at_point_of_booking',
            'redirect_to_confirmation_link_at_point_of_booking',
            'is_required_reservation_email_verification',
            'reservation_mobile_number',
            'is_required_reservation_mobile_number',
            'ssl_ready',
          ] as (keyof BrandConfig)[],
          'properties[frontend_style]': [
            'font_family',
            'primary_color',
            'secondary_color',
            'id',
            'slogan',
            'image',
            'images',
            'favicon',
            'favicons',
            'gh_icon',
            'homebackgroundimages',
            'testimonial_images',
            'seo_friendly_title',
            'meta_description',
            'focus_keyword',
            'facebook_title',
            'facebook_description',
            'facebook_image',
            'twitter_title',
            'twitter_description',
            'twitter_image',
            'meta_robots',
            'allow_show_in_search_engine',
            'follow_link_in_the_page',
            'tawk',
            'homepage_css',
            'custom_page_css',
            'blog_page_css',
            'destination_page_css',
            'country_page_css',
            'city_page_css',
            'same_as_link',
            'home_text',
            'google_analytics_tracking_code',
            'google_search_console_verification_string',
            'google_tag_management_id',
            'gh_icon_disapproval_reasons',
            'global_css',
            'copyright_statement',
            'border_radius_type'
          ] as (keyof BrandFrontendStyle)[],
        },
      }),
    fetchAllBrands: () =>
      axios.get<AllBrandsApiResType[]>('/apis/brands', {
        params: {
          properties: ['id', 'name', 'updated_at', 'created_at'],
          'properties[frontend_style]': ['image', 'images'],
          'properties[organization_config]': [
            'acceptable_custom_domain',
            'booking_engine_link',
            'domain_approved',
          ],
        },
      }),
    fetchAllBrandsCount: () => axios.get<{ count: string }>('/apis/organization/count_all_brands'),
    fetchPosts: (brandId: number) =>
      axios.get<ContentPage[]>('/apis/contents/all', {
        params: {
          brand: brandId,
          type: 'blog',
        },
      }),
    updateFrontendStyle: (id: number, body: UpdateFrontendStyleBody) =>
      axios.put<BrandFrontendStyle>(`/apis/frontend_styles/${id}`, body),
    updateConfig: (id: number, body: UpdateConfigBody) =>
      axios.put<BrandConfig>(`/apis/organization_configurations/${id}`, body),
    fetchContactConfig: (id: number) => axios.get<ContactConfig>(`/apis/contact_configs/${id}`),
    updateContactConfig: (id: number, body: UpdateContactConfig) =>
      axios.put<ContactConfig>(`/apis/contact_configs/${id}`, body),
    createImages: (frontendStyleId: number, newFileId: number, priority: number) =>
      axios.post<{ file: File; frontend_style: string; id: number }>(
        '/apis/frontend_style_home_background_images',
        {
          frontend_style: frontendStyleId,
          homebackgroundimages: newFileId,
          priority,
        }
      ),
    updateBrand: (
      id: number,
      body: { menus?: (UpdateFrontendMenuBody | number)[]; faqs?: UpdateFaqs }
    ) => axios.put<Brand>(`/apis/brands/${id}`, body),
    deleteFrontendMenu: (id: number) => axios.delete(`/apis/frontend_menus/${id}`),
    fetchAllPages: (brandId: number) =>
      axios.get<AllPages>(`/apis/contents/all_pages?brand=${brandId}`),
    fetchListings: (
      brandId: number,
      isPublish: boolean,
      page: number,
      search: string,
      signal?: AbortSignal
    ) =>
      axios.get<ApiFormat<Property[]>>(`/apis/direct/properties`, {
        signal,
        params: {
          meta: 1,
          [isPublish ? 'brands' : 'unpublished_brand']: brandId,
          limit: 9,
          page,
          name: search,
          'order[name]': 'asc',
          properties: ['id', 'name', 'area', 'images'],
        },
      }),
    fetchListing: (id: number, brandId: number) =>
      axios.get<Property>(`/apis/direct/properties/${id}`, {
        params: {
          brands: brandId,
          properties: ['id', 'name', 'route', 'property_page_script', 'property_page_css'],
        },
      }),
    updateListing: (id: number, body: UpdateProperty) =>
      axios.put<Property>(`/apis/properties/${id}`, body),
    fetchCities: (brandId: number, page: number, search: string, signal?: AbortSignal) =>
      axios.get<ApiFormat<City[]>>(`/apis/cities`, {
        signal,
        params: {
          meta: 1,
          brands: brandId,
          limit: 30,
          'order[name]': 'asc',
          page,
          name: search,
        },
      }),
    fetchCity: (id: number, brandId: number) =>
      axios.get<City>(`/apis/cities/${id}?brands=${brandId}`),
    updateCity: (id: number, brandId: number, body: UpdateCity) =>
      axios.put<City>(`/apis/cities/${id}?brands=${brandId}`, body),
    fetchCountries: (brandId: number, page: number, search: string, signal?: AbortSignal) =>
      axios.get<ApiFormat<Country[]>>(`/apis/country_configs`, {
        signal,
        params: {
          meta: 1,
          brands: brandId,
          limit: 30,
          page,
          'order[country.name]': 'asc',
          'country.name': search || undefined,
        },
      }),
    fetchCountry: (id: number, brandId: number) =>
      axios.get<Country>(`/apis/country_configs/${id}?brands=${brandId}`),
    updateCountry: (id: number, brandId: number, body: UpdateCountry) =>
      axios.put<Country>(`/apis/country_configs/${id}?brands=${brandId}`, body),
    fetchContentPage: (id: number) => axios.get<ContentPage>(`/apis/contents/${id}`),
    createContentPage: (body: CreateContentPage) => axios.post<ContentPage>(`/apis/contents`, body),
    updateContentPage: (id: number, body: UpdateContentPage) =>
      axios.put<ContentPage>(`/apis/contents/${id}`, body),
    deleteContentPage: (id: number) => axios.delete(`/apis/contents/${id}`),
    fetchDestinationConfig: (id: number) =>
      axios.get<DestinationConfig>(`/apis/destination_configs/${id}`),
    updateDestinationConfig: (id: number, body: UpdateDestinationConfig) =>
      axios.put<DestinationConfig>(`/apis/destination_configs/${id}`, body),
    fetchTagsAndCategories: (params?: {
      brand: number;
      page?: number;
      type?: string;
      url?: string;
    }) =>
      axios.get<ApiFormat<TagAndCategory[]>>(`/apis/tags_and_categories`, {
        params: { ...params, meta: 1 },
      }),
    fetchTagAndCategory: (id: number) =>
      axios.get<TagAndCategory>(`/apis/tags_and_categories/${id}`),
    createTagAndCategory: (body: CreateTagAndCategory) =>
      axios.post<TagAndCategory>(`/apis/tags_and_categories`, body),
    updateTagAndCategory: (id: number, body: UpdateTagAndCategory) =>
      axios.put<TagAndCategory>(`/apis/tags_and_categories/${id}`, body),
    deleteTagAndCategory: (id: number) => axios.delete(`/apis/tags_and_categories/${id}`),
    checkDns: (orgConfigId: number) =>
      axios.get<CheckDnsResponse>(`/apis/check_dns/${orgConfigId}`),
    fetchBasicInfoBrands: () =>
      axios.get<BasicInfoBrand>('/apis/brands', {
        params: {
          'order[name]': 'asc',
          properties: ['id', 'name'],
        },
      }),
    fetchNewsletterSubscribersCount: (brandId?: number) =>
      axios.get<{ data: { id: number }[]; meta: Meta }>('/apis/newsletter_subscribers', {
        params: {
          meta: 1,
          properties: ['id'],
          limit: 0,
          ...(brandId && {
            brand: brandId,
          }),
        },
      }),
    fetchNewsletterSubscribersInfo: () =>
      axios.get<NewsletterSubscribersInfo>('/apis/newsletter_subscribers/info'),
    fetchNewsletterSubscribers: ({
      page,
      limit,
      signal,
      searchParams,
      headers,
      responseType,
      fields,
    }: {
      page?: number;
      limit?: number;
      signal?: AbortSignal;
      searchParams?: { [key: string]: any };
      headers?: {
        Accept: ExportFileType;
      };
      responseType?: 'blob';
      fields?: any[];
    }) =>
      axios.get('/apis/newsletter_subscribers', {
        signal,
        params: {
          meta: 1,
          ...(page && { page }),
          ...(limit && { limit }),
          ...searchParams,
          ...(fields && { fields }),
        },
        ...(headers && { headers }),
        ...(responseType && { responseType }),
      }),
    createNewsletterSubscriber: (body: CreateNewsletterSubscriber) =>
      axios.post<NewsletterSubscriber>('/apis/newsletter_subscribers', body),
    updateNewsletterSubscriber: (id: number, body: UpdateNewsletterSubscriber) =>
      axios.put<NewsletterSubscriber>(`/apis/newsletter_subscribers/${id}`, body),
    deleteNewsletterSubscriber: (id: number) => axios.delete(`/apis/newsletter_subscribers/${id}`),
    updateRoute: (id: number, body: UpdateRouteConfig) =>
      axios.put<RouteConfig>(`/apis/routes/${id}`, body),
    createRedirect: (body: CreateRedirectBodyType) =>
      axios.post<Redirect>('/apis/redirects', body),
    updateARedirect: (id: number, body: { url: string }) =>
      axios.put<Redirect>(`/apis/redirects/${id}`, body),
    deleteARedirect: (id: number) =>
      axios.delete(`/apis/redirects/${id}`),
  };
}

export default MarketingAPI();
